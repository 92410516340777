import * as React from "react"

import styled, { css } from "styled-components"

import AppPopUp from "./AppPopUp"
import SVGComingSoon from "../images/coming-soon.svg"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
`

const Text = styled.div`
  max-width: 500px;
  padding: 30px 30px;
  margin-top: 10px;
  margin-bottom: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  & * {
    text-align: center;
  }

  h2 {
    font-size: 36px;
    line-height: 38px;
    margin-bottom: 26px;

    ${props => props.theme.breakpoints.S} {
      font-size: 30px;
    }
  }
`

const Image = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  background: ${props => props.theme.colors.text}55;

  margin-top: 240px;
  margin-bottom: -200px;

  ${props => props.theme.breakpoints.XS} {
    margin-top: 60%;
    margin-bottom: -35%;
  }

  svg {
    max-width: 420px;
    transform: translateX(14%) translateY(calc(-80%));
  }
`

const AppPopUpComingSoon = (props: {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
}) => {
  const { t } = useTranslation()

  return (
    <AppPopUp isOpen={props.isOpen} setIsOpen={props.setIsOpen}>
      <Wrapper>
        <Text>
          <h2>{t("coming-soon.TITLE")}</h2>
          <p>{t("coming-soon.DESCRIPTION")}</p>
        </Text>
        <Image>
          <SVGComingSoon />
        </Image>
      </Wrapper>
    </AppPopUp>
  )
}

export default AppPopUpComingSoon
