import * as React from "react"

import styled, { css } from "styled-components"

import { ButtonCSS } from "../styles/cssButtons"
import SVGButtonClose from "../images/close.svg"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div<{ isOpen?: boolean }>`
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${props => `${props.theme.colors.white}AA`};
  backdrop-filter: blur(6px);

  display: flex;
  align-items: center;
  justify-content: center;

  transition: all 0.1s ease;

  ${props =>
    props.isOpen
      ? css`
          opacity: 1;
        `
      : css`
          opacity: 0;
          pointer-events: none !important;
          z-index: -10;
        `}
`

const PopUp = styled.div<{
  isOpen?: boolean
}>`
  position: relative;
  width: 100%;
  max-width: 750px;
  margin: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;

  transition: all 0.2s ease;

  ${props =>
    props.isOpen
      ? css`
          transform: none;
        `
      : css`
          transform: translateY(100px);
        `}
`

const ButtonCloseTop = styled.div`
  z-index: 10;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
`

const Content = styled.div`
  width: 100%;
  overflow: hidden;

  background: ${props => props.theme.colors.white};
  box-shadow: 0 0 50px ${props => props.theme.colors.black}44;
  border-radius: 60px;
`

const ButtonCloseBottom = styled.div`
  ${ButtonCSS}
  z-index: 10;
  position: absolute;
  bottom: 0;
  left: auto;
  right: auto;
  cursor: pointer;
  transform: translateY(25%);
`

const AppPopUp = (props: {
  isOpen: boolean
  setIsOpen: (v: boolean) => void
  children?: any
}) => {
  const { t } = useTranslation()

  const close = () => {
    props.setIsOpen(false)
  }

  return (
    <Wrapper isOpen={props.isOpen}>
      <PopUp isOpen={props.isOpen}>
        <ButtonCloseTop onClick={close}>
          <SVGButtonClose />
        </ButtonCloseTop>

        <Content>{props.children}</Content>

        <ButtonCloseBottom onClick={close}>
          {t("popup.CLOSE").toUpperCase()}
        </ButtonCloseBottom>
      </PopUp>
    </Wrapper>
  )
}

export default AppPopUp
