import * as React from "react"

import AppLayout from "../components/AppLayout"
import SectionAbout from "../components/SectionAbout"
import SectionIntro from "../components/SectionIntro"
import SectionScreens from "../components/SectionScreens"
import { graphql } from "gatsby"
import styled from "styled-components"

const Sections = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 5vh;
`

const Homepage: React.FC = () => {
  return (
    <AppLayout>
      <Sections>
        <SectionIntro />
        <SectionAbout />
        <SectionScreens />
      </Sections>
    </AppLayout>
  )
}

export default Homepage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
