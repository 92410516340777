import * as React from "react"

import styled, { css } from "styled-components"

import ArtboardAnim1 from "../images/artboards-animated/artboard-anim-1.webm"
import ArtboardAnim2 from "../images/artboards-animated/artboard-anim-2.webm"
import ArtboardAnim3 from "../images/artboards-animated/artboard-anim-3.webm"
import SVGCalendar from "../images/iconCalendar.svg"
import SVGGraph from "../images/iconGraph.svg"
import SVGVaccine from "../images/iconVaccine.svg"
//import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${props => props.theme.breakpoints.M} {
    column-gap: 3vh;
  }
  ${props => props.theme.breakpoints.S} {
    column-gap: 6vh;
  }
`

const SectionWrapper = styled.div<{ reversed: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: ${props => (props.reversed ? "row-reverse" : "row")};

  ${props => props.theme.breakpoints.S} {
    flex-direction: column;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;

  ${props => props.theme.breakpoints.S} {
    align-items: center;
  }
`

const Icon = styled.div`
  svg {
    width: 57px;
  }
`

const Title = styled.h2`
  width: 100%;
  margin-top: 4px;
  margin-bottom: min(20px, 2vh);

  ${props => props.theme.breakpoints.S} {
    text-align: center;
  }
`

const Paragraph = styled.p`
  ${props => props.theme.breakpoints.S} {
    max-width: 450px;
    text-align: center;
  }
`

const GraphicContent = styled.div<{
  reversed: boolean
  noBackground?: boolean
}>`
  min-width: 49%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${props =>
    props.noBackground ? "none" : props.theme.colors.background};
  border-radius: 60px;
  box-shadow: 0 0 40px
    ${props => (props.noBackground ? "none" : `${props.theme.colors.black}22`)};
  margin: 80px 0px;
  aspect-ratio: 8/5;
  mix-blend-mode: darken;

  ${props =>
    props.reversed
      ? css`
          margin-right: 7%;
        `
      : css`
          margin-left: 7%;
        `}

  ${props => props.theme.breakpoints.S} {
    min-width: 80%;
    margin: 50px -10%;
    margin-bottom: 100px;
    border-radius: 30px;
  }

  & > * {
    position: absolute;
    width: 130%;
  }
`

const Video = styled.video`
  width: 110%;

  ${props => props.theme.breakpoints.S} {
    width: 130%;
  }
`

const SubSection = (props: {
  code: "diary" | "plan" | "progress"
  icon: any
  reversed?: boolean
}) => {
  const { t } = useTranslation()

  return (
    <SectionWrapper reversed={props.reversed}>
      <TextContent>
        <Icon>{props.icon}</Icon>
        <Title>{t(`section.about_${props.code}.TITLE`)}</Title>
        <Paragraph>{t(`section.about_${props.code}.PARAGRAPH`)}</Paragraph>
      </TextContent>

      {/* <GraphicContent reversed={props.reversed}>
        {props.code === "diary" ? (
          <StaticImage
            src="../images/artboards/Artboard 2@4x_web.png"
            alt="ArtBoard"
          />
        ) : props.code === "plan" ? (
          <StaticImage
            src="../images/artboards/Artboard 1@4x_web.png"
            alt="ArtBoard"
          />
        ) : (
          <StaticImage
            src="../images/artboards/Artboard 3@4x_web.png"
            alt="ArtBoard"
          />
        )}
      </GraphicContent> */}

      <GraphicContent reversed={props.reversed} noBackground>
        {props.code === "diary" ? (
          <Video preload="auto" loop autoPlay muted>
            <source src={ArtboardAnim3} type="video/webm" />
          </Video>
        ) : props.code === "plan" ? (
          <Video preload="auto" loop autoPlay muted>
            <source src={ArtboardAnim1} type="video/webm" />
          </Video>
        ) : (
          <Video preload="auto" loop autoPlay muted>
            <source src={ArtboardAnim2} type="video/webm" />
          </Video>
        )}
      </GraphicContent>
    </SectionWrapper>
  )
}

const SectionAbout = () => {
  return (
    <Wrapper id="ID_SECTION_ABOUT">
      <SubSection code="plan" icon={<SVGCalendar />} />
      <SubSection code="progress" icon={<SVGVaccine />} reversed />
      <SubSection code="diary" icon={<SVGGraph />} />
    </Wrapper>
  )
}

export default SectionAbout
