import * as React from "react"

import styled, { css } from "styled-components"

import AppPopUpComingSoon from "./AppPopUpComingSoon"
import { ButtonCSS } from "../styles/cssButtons"
import SVGButtonAppStore from "../images/buttonAppStore.svg"
import SVGButtonGooglePlay from "../images/buttonGooglePlay.svg"
import SVGVideo from "../images/video.svg"
import { StaticImage } from "gatsby-plugin-image"
import config from "../../config"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div``

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: min(20px, 2vh);

  ${props => props.theme.breakpoints.S} {
    align-items: center;
  }
`

const Title = styled.h1`
  width: 100%;
  max-width: 400px;
  max-width: min(calc(240px + 20vw), 500px);

  ${props => props.theme.breakpoints.S} {
    text-align: center;
  }
`

const Paragraph = styled.p`
  width: 100%;
  max-width: 450px;
  max-width: min(calc(200px + 18vw), 450px);

  ${props => props.theme.breakpoints.S} {
    max-width: 450px;
    text-align: center;
  }
`

const GraphicContent = styled.div`
  position: relative;
  display: flex;
  width: 95%;
  margin-top: -40px;
  margin-left: 5%;

  ${props => props.theme.breakpoints.S} {
    width: 120%;
    margin-left: -10%;
    margin-top: 110px;
    margin-bottom: 60px;
  }

  ${props => props.theme.breakpoints.XS} {
    width: 150%;
    margin-left: -30%;
    margin-top: 25%;
  }
`

const ImagePets = styled.div`
  width: 60%;

  ${props => props.theme.breakpoints.S} {
    width: 55%;
  }
`

const ImagePhones = styled.div`
  position: absolute;
  top: -50%;
  right: -8%;
  width: 80%;

  ${props => props.theme.breakpoints.S} {
    width: 80%;
    top: -50%;
    right: -2%;
  }
`

const Buttons = styled.div`
  z-index: 10;
  position: relative;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2%;
  gap: 10px;
  row-gap: 10px;

  & > * {
    cursor: pointer;

    svg {
      margin-top: 10px;
    }
  }

  ${props => props.theme.breakpoints.S} {
    margin-top: 40px;
    justify-content: center;
  }
`

const ButtonLink = styled.a`
  ${ButtonCSS}
  gap: 10px;

  svg {
    margin: 0;
    width: 28px;
  }
`

const ImageLink = styled.a<{ faded?: boolean }>`
  ${props =>
    props.faded &&
    css`
      opacity: 0.6;
    `}
`

const SectionIntro: React.FC = () => {
  const { t } = useTranslation()

  const [devPopUpIsOpen, setDevPopUpIsOpen] = React.useState(false)

  const preventLinkClick = e => {
    e.preventDefault()
    setDevPopUpIsOpen(true)
  }

  return (
    <>
      <Wrapper>
        <TextContent>
          <Title>{t("section.home.TITLE")}</Title>
          <Paragraph>{t("section.home.PARAGRAPH")}</Paragraph>
        </TextContent>

        <Buttons>
          <ButtonLink
            href={config.videoLink}
            target="_blank"
            /* onClick={preventLinkClick} */
          >
            <SVGVideo />
            {t("section.home.VIDEO")}
          </ButtonLink>
        </Buttons>

        <GraphicContent>
          <ImagePets>
            <StaticImage
              src="../images/puppy-french-bulldog-cat@2x.png"
              alt="Dog with a cat"
            />
          </ImagePets>
          <ImagePhones>
            <StaticImage src="../images/phones@2x.webp" alt="Phones" />
          </ImagePhones>
        </GraphicContent>

        <Buttons id="ID_SECTION_DOWNLOAD">
          <ImageLink
            href={config.downloadGooglePlay}
            target="_blank"
            /* onClick={preventLinkClick} */
            /* faded */
          >
            <SVGButtonGooglePlay />
          </ImageLink>

          <ImageLink
            href={config.downloadAppStore}
            target="_blank"
            /* onClick={preventLinkClick} */
          >
            <SVGButtonAppStore />
          </ImageLink>

          <ButtonLink
            href={config.appInBrowser}
            target="_blank"
            /* onClick={preventLinkClick} */
          >
            {t("section.home.APP_IN_BROWSER")}
          </ButtonLink>
        </Buttons>
      </Wrapper>

      <AppPopUpComingSoon
        isOpen={devPopUpIsOpen}
        setIsOpen={setDevPopUpIsOpen}
      />
    </>
  )
}

export default SectionIntro
