import { css } from "styled-components"

export const ButtonCSS = css`
  text-decoration: none;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  text-align: center;
  white-space: pre-wrap;
  font-size: min(calc(12px + 0.3vw), 16px);
  line-height: 1.1rem;
  font-weight: 600;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 51px;
  margin-top: 4px;
`
