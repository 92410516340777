import * as React from "react"

import styled, { css } from "styled-components"

import SVGArrowNext from "../images/arrowNext.svg"
import SVGArrowPrev from "../images/arrowPrev.svg"
import { StaticImage } from "gatsby-plugin-image"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  background: ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  width: 100vw;
  align-self: center;
  padding: 40px 20px;
  padding-bottom: max(80px, 12vh);

  margin-top: 5vh;
  ${props => props.theme.breakpoints.S} {
    margin-top: 0;
  }
`

const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Title = styled.h2`
  width: 80%;
  text-align: center;
`

const Paragraph = styled.p`
  width: 90%;
  max-width: 500px;
  text-align: center;
  margin-top: 25px;
  margin-bottom: 35px;
`

const GraphicContent = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Arrows = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  z-index: 2000;

  ${props => props.theme.breakpoints.XS} {
    bottom: 0px;
  }
`

const ArrowSpacer = styled.div`
  pointer-events: none;
  width: 700px;

  ${props => props.theme.breakpoints.M} {
    width: 600px;
  }
  ${props => props.theme.breakpoints.S} {
    width: 430px;
  }
  ${props => props.theme.breakpoints.XS} {
    width: 200px;
  }
`

const Arrow = styled.div`
  cursor: pointer;
`

const ImageScreen = styled.div<{
  scale: number
  offset: number
  zIndex: number
}>`
  z-index: ${props => props.zIndex};
  position: absolute;
  top: 0;
  transition: all 0.2s ease;
  user-select: none !important;
  overflow: hidden;
  box-shadow: 0 0 40px ${props => props.theme.colors.black}66;
  border-radius: 20px;
  width: 300px;

  transform: ${props =>
    `scale(${props.scale}) translateX(calc(${props.offset} * 160px))`};

  ${props => props.theme.breakpoints.M} {
    transform: ${props =>
      `scale(${props.scale}) translateX(calc(${props.offset} * 140px))`};
  }

  ${props => props.theme.breakpoints.S} {
    width: 220px;
    transform: ${props =>
      `scale(${props.scale}) translateX(calc(${props.offset} * 100px))`};
  }

  ${props => props.theme.breakpoints.XS} {
    width: 160px;
    transform: ${props =>
      `scale(${props.scale}) translateX(calc(${props.offset} * 70px))`};
  }

  img {
    width: 100%;
    object-fit: contain;
  }
`

const SectionScreens: React.FC = () => {
  const { t } = useTranslation()

  const screenCount = 11
  const centerIndex = (screenCount - 1) / 2
  const [activeIndex, setActiveIndex] = React.useState(centerIndex)

  const getTransform = (itemIndex: number) => {
    const index = itemIndex % screenCount

    if (index === centerIndex) {
      return { scale: 1, offset: 0, zIndex: 1000 }
    }
    const diff = index > centerIndex ? index - centerIndex : centerIndex - index
    return {
      scale: 0.8 - diff * 0.1,
      offset: index - centerIndex,
      zIndex: 1000 - Math.abs(diff),
    }
  }

  return (
    <Wrapper>
      <TextContent>
        <Title>{t("section.screens.TITLE")}</Title>
        <Paragraph>{t("section.screens.PARAGRAPH")}</Paragraph>
      </TextContent>

      <GraphicContent>
        <ImageScreen
          {...getTransform(centerIndex)}
          style={{ opacity: 0, position: "relative" }}
        >
          {/* FOR DYNAMIC HEIGHT OF ABSOLUTELY POSITIONED ITEMS */}
          <StaticImage src="../images/screens/1.png" alt="screen" />
        </ImageScreen>

        <Arrows>
          <Arrow>
            <SVGArrowPrev onClick={() => setActiveIndex(activeIndex + 1)} />
          </Arrow>
          <ArrowSpacer />
          <Arrow>
            <SVGArrowNext
              onClick={() =>
                setActiveIndex(
                  activeIndex === 0 ? screenCount - 1 : activeIndex - 1
                )
              }
            />
          </Arrow>
        </Arrows>

        <ImageScreen {...getTransform(activeIndex)}>
          <StaticImage src="../images/screens/1.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 1)}>
          <StaticImage src="../images/screens/2.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 2)}>
          <StaticImage src="../images/screens/3.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 3)}>
          <StaticImage src="../images/screens/4.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 4)}>
          <StaticImage src="../images/screens/5.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 5)}>
          <StaticImage src="../images/screens/6.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 6)}>
          <StaticImage src="../images/screens/7.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 7)}>
          <StaticImage src="../images/screens/8.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 8)}>
          <StaticImage src="../images/screens/9.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 9)}>
          <StaticImage src="../images/screens/10.png" alt="screen" />
        </ImageScreen>
        <ImageScreen {...getTransform(activeIndex + 10)}>
          <StaticImage src="../images/screens/11.png" alt="screen" />
        </ImageScreen>
      </GraphicContent>
    </Wrapper>
  )
}

export default SectionScreens
